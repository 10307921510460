<template>
  <b-div v-if="dataFetched">
    <b-container fluid="xl">
      <div class="row">
        <b-col>
          <h4>
            <span @click="collapseAll" class="category-title m-2">
              Collapse All
            </span>
            <span @click="expandAll" class="category-title m-2">
              Expand All
            </span>
          </h4>
        </b-col>
      </div>
      <div class="row mb-2">
        <b-col>
          <b-input-group>
            <b-form-input
              v-model="filterQuery"
              class="form-control filter-input"
              type="text"
              placeholder="Filter"
            >
            </b-form-input>
          </b-input-group>
        </b-col>
      </div>
    </b-container>
    <CategoryItems v-for="sheet in spreadsheetSheetsList" :key=sheet.properties.title :category=sheet.properties.title :items=itemQuery[sheet.properties.title] />
  </b-div>
</template>

<script>
import CategoryItems from './categories/CategoryItems'

import {
  mapActions,
  mapGetters
} from 'vuex'

export default {
  name: 'Items',
  data () {
    return {
      filterQuery: null,
      dataFetched: false,
      totalCategoryCount: 0
    }
  },
  components: {
    CategoryItems
  },
  methods: {
    ...mapActions(['filterItems', 'fetchSpreadsheetSheets', 'fetchSpreadsheetItems', 'fetchSpreadsheetSheetsWithData']),
    collapseAll () {
      document.querySelectorAll(':not(.collapsed) > .when-closed').forEach(toggle => toggle.click())
    },
    expandAll () {
      document.querySelectorAll('.collapsed > .when-opened').forEach(toggle => toggle.click())
    },
    // This is unused as it makes too many calls
    fetchIndividualSheets () {
      this.fetchSpreadsheetSheets({
        vm: this
      }).then(() => {
        this.totalCategoryCount = this.spreadsheetSheetsList.length
        this.spreadsheetSheetsList.forEach((sheet) => {
          this.fetchSpreadsheetItems({
            vm: this,
            title: sheet.properties.title
          }).then(() => {
            if (self.totalCategoryCount !== 0) {
              // Set dataFetched to true to render components
              if (parseInt(Object.keys(this.spreadsheetItemsList).length) === parseInt(this.totalCategoryCount)) {
                this.dataFetched = true
              }
            }
          })
        })
      })
    }
  },
  computed: {
    ...mapGetters(['itemsList', 'filteredItemList', 'spreadsheetItemsList', 'spreadsheetSheetsList']),
    itemQuery () {
      if (this.filterQuery) {
        this.filterItems(this.filterQuery)
        return this.filteredItemList
      } else {
        return this.spreadsheetItemsList
      }
    }
  },
  created () {
    // Fetch the spreadsheets
    this.fetchSpreadsheetSheetsWithData({
      vm: this
    }).then(() => {
      this.dataFetched = true
    })
  }
}
</script>

<style>
.category-title {
  font-family: 'Bebas Neue', sans-serif;
}
.va-center {
  vertical-align:middle;
}
</style>
