import axiosInstance from '../../axiosInstance'

const state = {
  filteredItems: null,
  apiKey: 'AIzaSyCLSBHZbuTp3F2S45YnW3Eah8RonkBIloU',
  sheetID: '1u1Hggl01RD0qRR8t8Owubz_jAeaLhdp5c6UlbnfalNU',
  spreadsheetSheets: null,
  spreadsheetItems: {},
  categories: [],
  items: {
    top10Items: [
      {
        url: 'https://amzn.to/41DHQH5',
        img: 'https://m.media-amazon.com/images/I/81ZBIkPTKjL._AC_SL1500_.jpg',
        title: 'Rechargeable Fabric Shaver, Magictec Lint Remover Sweater Defuzzer Lints Fuzzs Pills Pilling Trimmer for Clothes and Furniture -Battery Operated'
      },
      {
        url: 'https://amzn.to/3W5tNsI',
        img: 'https://m.media-amazon.com/images/I/61wvxyaywHL._AC_.jpg',
        title: 'ARTIOMO Collapsible Stools for Adults - Foldable Stools for Adults, Collapsible Stool, Foldable Stool for Camping, Shopping, Hiking, Fishing, Black'
      },
      {
        url: 'https://amzn.to/3IcbYm7',
        img: 'https://m.media-amazon.com/images/I/71Bw206MUpL._AC_SL1500_.jpg',
        title: 'Arofa Handheld Bidet Sprayer for Toilet-Adjustable Water Pressure Control with Bidet Hose for Feminine Wash, Stainless Steel Brushed Nickel Cloth Diaper Bidet Toilet Sprayer for Baby Wash'
      },
      {
        url: 'https://amzn.to/41I9vqq',
        img: 'https://m.media-amazon.com/images/I/81hFSlEVnmL._AC_SL1500_.jpg',
        title: 'Filtrete 10x20x1 Air Filter MPR 1000 MERV 11, Allergen Defense, 4-Pack (exact dimensions 9.81x19.81x0.81)'
      },
      {
        url: 'https://amzn.to/3BtxG1i',
        img: 'https://m.media-amazon.com/images/I/51pO+G+cREL._AC_SL1500_.jpg',
        title: 'ForeverPRO 54612 Keygr 800 for Speed Queen Appliance 647110 M404608'
      },
      {
        url: 'https://amzn.to/3IeBeZ0',
        img: 'https://m.media-amazon.com/images/I/81e6riDMPSL._AC_SL1500_.jpg',
        title: 'Lysol Disinfectant Wipes, Multi-Surface Antibacterial Cleaning Wipes, For Disinfecting and Cleaning, Lemon and Lime Blossom, 240 Count (Pack of 3)'
      },
      {
        url: 'https://amzn.to/3MdKRbI',
        img: 'https://m.media-amazon.com/images/I/61enqxJ7KfL._SL1249_.jpg',
        title: 'SURVIVEWARE Biodegradable Wet Wipes, Face and Body Wipes for Post Workout and Camping, Wipes for Adults, Large Wipes, 32 Count'
      },
      {
        url: 'https://amzn.to/3Btfchu',
        img: 'https://m.media-amazon.com/images/I/514GJ3gwLML._AC_SL1000_.jpg',
        title: 'KIPIDA Solar Shower Bag,5 gallons/20L Solar Heating Camping Shower Bag with Removable Hose and On-Off Switchable Shower Head for Camping Beach Swimming Outdoor Traveling Hiking'
      },
      {
        url: 'https://amzn.to/3pIHLEZ',
        img: 'https://m.media-amazon.com/images/I/61mVdemg0fL._AC_SL1500_.jpg',
        title: 'Stanley Classic Vacuum Insulated Wide Mouth Bottle (1.1 QT, 2 QT)'
      }
    ],
    apple: [
      {
        url: 'https://amzn.to/45e0Jnc',
        img: 'https://m.media-amazon.com/images/I/61goypdjAYL._AC_SL1500_.jpg',
        title: 'Apple iPad (10th Generation): with A14 Bionic chip, 10.9-inch Liquid Retina Display, 64GB, Wi-Fi 6, 12MP front/12MP Back Camera, Touch ID, All-Day Battery Life – Silver'
      },
      {
        url: 'https://amzn.to/41y8AsJ',
        img: 'https://m.media-amazon.com/images/I/81jqUPkIVRL._AC_SL1500_.jpg',
        title: 'Apple AirPods Max - Space Gray'
      },
      {
        url: 'https://amzn.to/42yqtZA',
        img: 'https://m.media-amazon.com/images/I/71bhWgQK-cL._AC_SL1500_.jpg',
        title: 'Apple AirPods Pro (2nd Generation) Wireless Earbuds, Up to 2X More Active Noise Cancelling, Adaptive Transparency, Personalized Spatial Audio, MagSafe Charging Case, Bluetooth Headphones for iPhone'
      },
      {
        url: 'https://amzn.to/3M4efRC',
        img: 'https://m.media-amazon.com/images/I/719C6bJv8jL._AC_SL1500_.jpg',
        title: 'Apple 2022 MacBook Air Laptop with M2 chip: 13.6-inch Liquid Retina Display, 8GB RAM, 256GB SSD Storage, Backlit Keyboard, 1080p FaceTime HD Camera. Works with iPhone and iPad; Midnight'
      },
      {
        url: 'https://amzn.to/3pIItSF',
        img: 'https://m.media-amazon.com/images/I/61bwiPRcv2L._AC_SL1500_.jpg',
        title: 'Apple 2023 MacBook Pro Laptop M2 Pro chip with 12‑core CPU and 19‑core GPU: 16.2-inch Liquid Retina XDR Display, 16GB Unified Memory, 512GB SSD Storage. Works with iPhone/iPad; Silver'
      }
    ],
    books: [
    ],
    cleaning: [
      {
        url: 'https://amzn.to/41EkuB6',
        img: 'https://m.media-amazon.com/images/I/71SzLTvO2PL._AC_SL1500_.jpg',
        title: 'Tide Liquid Laundry Detergent Soap, High Efficiency (HE), Original Scent, 64 Loads'
      },
      {
        url: 'https://amzn.to/3Ic5Km5',
        img: 'https://m.media-amazon.com/images/I/81upYSg4MHL._AC_SL1500_.jpg',
        title: 'Scotch-Brite Non-Scratch Scrub Sponges, For Washing Dishes and Cleaning Kitchen, 9 Scrub Sponges'
      },
      {
        url: 'https://amzn.to/4539LDb',
        img: 'https://m.media-amazon.com/images/I/81-q-UfjbOL._AC_SL1500_.jpg',
        title: 'Simple Green Outdoor Odor Eliminator for Pets, Dogs, Ideal for Artificial Grass & Patio'
      },
      {
        url: 'https://amzn.to/3O6HoOB',
        img: 'https://m.media-amazon.com/images/I/81NT+bitRyL._AC_SL1500_.jpg',
        title: 'O-Cedar EasyWring RinseClean Microfiber Spin Mop & Bucket Floor Cleaning System with 2 Extra Refills, Grey'
      },
      {
        url: 'https://amzn.to/3OedWq4',
        img: 'https://m.media-amazon.com/images/I/81wUWVAiWDL._AC_SL1500_.jpg',
        title: 'Amazon Basics Microfiber Cleaning Cloths, Non-Abrasive, Reusable and Washable - Pack of 24, 12 x16-Inch, Blue, White and Yellow'
      },
      {
        url: 'https://amzn.to/3IcYTZA',
        img: 'https://m.media-amazon.com/images/I/81Kfb6jdmWL._AC_SL1500_.jpg',
        title: 'Cottonelle FreshFeel Flushable Wet Wipes for Adults and Kids, 8 Flip-Top Packs, 42 Wipes per Pack (336 Wipes Total)'
      },
      {
        url: 'https://amzn.to/44VCkmd',
        img: 'https://m.media-amazon.com/images/I/81vzp9ozsYL._AC_SL1500_.jpg',
        title: 'PURELL Advanced Hand Sanitizer Variety Pack, Naturals and Refreshing Gel, 1 Fl Oz Travel Size Flip-Cap Bottle with Jelly Wrap Carrier (Pack of 8) - 3900-09-ECSC'
      },
      {
        url: 'https://amzn.to/3o5D3kc',
        img: 'https://m.media-amazon.com/images/I/51uQgrWBUQS._SL1500_.jpg',
        title: 'Clorox Commercial Solutions Clorox Odor Defense Aerosol, 14 Ounces'
      },
      {
        url: 'https://amzn.to/3o7PEmH',
        img: 'https://m.media-amazon.com/images/I/71DMy4ALNrL._AC_SL1500_.jpg',
        title: 'Earth Rated Dog Poop Bags, Extra Thick and Strong Poop Bags for Dogs, Guaranteed Leak-proof, 15 Doggy Bags Per Roll, Each Dog Poop Bag Measures 9 x 13 Inches'
      },
      {
        url: 'https://amzn.to/42V1D66',
        img: 'https://m.media-amazon.com/images/I/81Un+Vn0bNL._SL1500_.jpg',
        title: 'Softsoap Moisturizing Liquid Hand Soap, Soothing Clean Aloe Vera - 7.5 Fl Oz (Pack of 6)'
      }
    ],
    clothing: [
      {
        url: 'https://amzn.to/3pM7EU7',
        img: 'https://m.media-amazon.com/images/I/71IGc5w9C5L._AC_UX679_.jpg',
        title: 'ATHVOTAR Women\'s High Waisted Biker Shorts with Side Pockets Tummy Control Spandex Shorts'
      },
      {
        url: 'https://amzn.to/3o0g8GM',
        img: 'https://m.media-amazon.com/images/I/81yS-M+6QcL._AC_SL1500_.jpg',
        title: '(3 Pack) Black Athletic Tape - 45ft Per Roll - No Sticky Residue & Easy to Tear - for Sports Athletes & Crossfit Trainers as First Aid Injury Wrap: Fingers Ankles Wrist - 1.5 Inch x 15 Yards per Roll'
      },
      {
        url: 'https://amzn.to/3W2KHs1',
        img: 'https://m.media-amazon.com/images/I/716XcTEbsDL._AC_SL1500_.jpg',
        title: 'Alllvocles Resistance Band, Pull Up Bands, Pull Up Assistance Bands, Workout Bands, Exercise Bands, Resistance Bands Set for Legs, Working Out, Muscle Training, Physical Therapy, Shape Body, Men Women'
      }
    ],
    games: [
    ],
    home: [
      {
        url: 'https://amzn.to/3W6ZQbF',
        img: 'https://m.media-amazon.com/images/I/71H8ypSYBtL._AC_SL1500_.jpg',
        title: 'Amazon Basics 1/2-Inch Extra Thick Exercise Yoga Mat'
      },
      {
        url: 'https://amzn.to/3Mt1bqc',
        img: 'https://m.media-amazon.com/images/I/81FYBknuT4L._AC_SL1500_.jpg',
        title: 'Diapers Size 5, 132 Count - Pampers Pure Protection Hypoallergenic Disposable Baby Diapers for Sensitive Skin, Fragrance Free, ONE Month Supply (Packaging May Vary)'
      },
      {
        url: 'https://amzn.to/3OhtYQ1',
        img: 'https://m.media-amazon.com/images/I/41ikVpNeM6L._AC_.jpg',
        title: 'Heavy Weight Jumbo Size Dobby Hem Velour Beach Towel (Black) 36” x 72”'
      },
      {
        url: 'https://amzn.to/3pL7E7a',
        img: 'https://m.media-amazon.com/images/I/A1wqwpLFMVL._AC_SL1500_.jpg',
        title: 'Terry Town DP1718-BROWN-1 Rustic Ranch Throw w/Bonded Beige Mink, Brown'
      },
      {
        url: 'https://amzn.to/42zXBjC',
        img: 'https://m.media-amazon.com/images/I/71riquIp4dL._AC_SL1500_.jpg',
        title: '6/4.1/3.2 Inch Self Watering Planter Pots, 5 Pack African Violet Pots for Indoor Outdoor Windowsill Gardens, Self Aerating, High Drainage, Deep Reservoir(Gray)'
      },
      {
        url: 'https://amzn.to/41DHe4f',
        img: 'https://m.media-amazon.com/images/I/81CEoNir36L._AC_SL1500_.jpg',
        title: 'Stanley Adventure Big Grip Beer Stein, 24oz Stainless Steel Beer Mug, Double Wall Vacuum Insulation'
      },
      {
        url: 'https://amzn.to/3IbmeuO',
        img: 'https://m.media-amazon.com/images/I/81o64FA0roS._SL1500_.jpg',
        title: 'Oral B Glide Complete With Scope Outlast Dental Floss Picks, Mint, 75 Count, Pack Of 6, 6 count'
      },
      {
        url: 'https://amzn.to/3MD6OlX',
        img: 'https://m.media-amazon.com/images/I/61NUH8C3FOL._SL1000_.jpg',
        title: 'Colgate Optic White Advanced Teeth Whitening Toothpaste with Fluoride, 2% Hydrogen Peroxide, Sparkling White - 3.2 Ounce (3 Pack)'
      }
    ],
    kitchen: [
      {
        url: 'https://amzn.to/3O5LSoG',
        img: 'https://m.media-amazon.com/images/I/91dN0IWNyjL._SL1500_.jpg',
        title: 'Pellegrino Water 24 Pack'
      },
      {
        url: 'https://amzn.to/41CjZHJ',
        img: 'https://m.media-amazon.com/images/I/81te0dgkN4L._SL1500_.jpg',
        title: 'Kraft Easy Mac Original Macaroni & Cheese Microwavable Dinner (18 ct Packets)'
      },
      {
        url: 'https://amzn.to/3pKZZFz',
        img: 'https://m.media-amazon.com/images/I/71n1nFOiqVL._AC_SL1500_.jpg',
        title: 'Stanley Adventure Camp Cook Set - 24oz Kettle with 2 Cups - Stainless Steel Camping Cookware with Vented Lids & Foldable + Locking Handle - Lightweight Cook Pot for Backpacking/Hiking/Camping'
      },
      {
        url: 'https://amzn.to/3W7PfNC',
        img: 'https://m.media-amazon.com/images/I/61y4ojakB5L._AC_SL1500_.jpg',
        title: 'MYCHA Japanese Matcha Tea Set, Matcha Whisk, Traditional Scoop, Matcha Bowl, Ceramic Whisk Holder,Sifter,Handmade Matcha Ceremony Kit For Traditional Japanese Tea Ceremony (Cherry Blossom)'
      },
      {
        url: 'https://amzn.to/3nV3RDL',
        img: 'https://m.media-amazon.com/images/I/71IhI4jF+uL._AC_SL1500_.jpg',
        title: 'Premium Japanese Ceremonial Matcha Green Tea Chawan Bowl Full Kit Matcha Whisk Set with Accessories and Tools Bamboo Chasen Matcha Whisk Scoop and Holder'
      },
      {
        url: 'https://amzn.to/3ObeGMk',
        img: 'https://m.media-amazon.com/images/I/41usQcR+FzL._AC_.jpg',
        title: 'Textured Glass Matcha Bowl With Pouring Spout - Handmade Japanese Style Matcha Green Tea Ceremony Chawan 400ml 13.5 oz Big Glass Salad Porridge Juice Bowl Cup'
      },
      {
        url: 'https://amzn.to/3BuFM9G',
        img: 'https://m.media-amazon.com/images/I/61Qf8Ztg1FL._AC_SL1500_.jpg',
        title: 'Countertop Ice Maker, Ice Maker Machine 6 Mins 9 Bullet Ice, 26.5lbs/24Hrs, Portable Ice Maker Machine with Self-Cleaning, Ice Bags, Ice Scoop, and Basket, Ice Maker for Home/Kitchen/Office/Party'
      },
      {
        url: 'https://amzn.to/3o1wwH4',
        img: 'https://m.media-amazon.com/images/I/71Q5F8c4+NS._AC_SL1500_.jpg',
        title: 'Ice Shaker 26 Oz Shaker Bottle, Stainless Steel Water Bottle and Protein Shaker, As Seen on Shark Tank, Reusable Stainless Steel Water Bottle, Gym Water Bottle, Black'
      },
      {
        url: 'https://amzn.to/3nWdfXL',
        img: 'https://m.media-amazon.com/images/I/81sBMHSSccL._AC_SL1500_.jpg',
        title: 'Olicity Cheesecloth, Grade 100, 20x20Inch Hemmed Cheese Cloths for Straining Reusable, 100% Unbleached Cheese Cloth Strainer Muslin Cloth for Cooking, Straining, Jelly Making, Cheese Making - 2 Pieces'
      }
    ],
    tech: [
      {
        url: 'https://amzn.to/431DVVP',
        img: 'https://m.media-amazon.com/images/I/511YSGFPANS._AC_SL1500_.jpg',
        title: 'Anker Powerline II Lightning Cable, [6ft MFi Certified] USB Charging/Sync Lightning Cord Compatible with iPhone SE 11 11 Pro 11 Pro Max Xs MAX XR X 8 7 6S 6 5, iPad and More'
      },
      {
        url: 'https://amzn.to/44TTXCK',
        img: 'https://m.media-amazon.com/images/I/81GAaZTUHuL._AC_SL1500_.jpg',
        title: 'PowerBear 4K HDMI Cable 10 ft | High Speed Hdmi Cables, Braided Nylon & Gold Connectors, 4K @ 60Hz, Ultra HD, 2K, 1080P, ARC & CL3 Rated | for Laptop, Monitor, PS5, PS4, Xbox One, Fire TV, & More'
      },
      {
        url: 'https://amzn.to/3Ic7kVd',
        img: 'https://m.media-amazon.com/images/I/81qKlaBBoZL._AC_SL1500_.jpg',
        title: 'Anker Portable Charger, 325 Power Bank (PowerCore Essential 20K) 20000mAh Battery Pack with High-Speed PowerIQ Technology and USB-C (Input Only) for iPhone, Samsung Galaxy, and More'
      },
      {
        url: 'https://amzn.to/3BsFXSY',
        img: 'https://m.media-amazon.com/images/I/61+T2xNzR7S._AC_SL1000_.jpg',
        title: 'Fire TV Stick with Alexa Voice Remote (includes TV controls), HD streaming device'
      },
      {
        url: 'https://amzn.to/3Id3Fql',
        img: 'https://m.media-amazon.com/images/I/81Eqgg0cvrL._AC_SL1500_.jpg',
        title: 'Echo Dot (5th Gen, 2022 release) | With bigger vibrant sound, helpful routines and Alexa | Charcoal'
      },
      {
        url: 'https://amzn.to/3Ieayrn',
        img: 'https://m.media-amazon.com/images/I/71aBXDeJwuL._AC_SL1500_.jpg',
        title: 'Meta Portal - Smart Video Calling for the Home with 10” Touch Screen Display - White'
      },
      {
        url: 'https://amzn.to/42ZeGmL',
        img: 'https://m.media-amazon.com/images/I/41Mhi6V4vSL._AC_.jpg',
        title: 'USB Hub, 4 Port USB Adapter Ultra-Slim USB Splitter, Multi USB Port Expander USB Extender Lead Adapter for PC, Laptop, Desktop, Notebook, MacBook, Netbook, MAC, Wii'
      }
    ],
    toys: [
      {
        url: 'https://amzn.to/3M6Plk7',
        img: 'https://m.media-amazon.com/images/I/612z4la3LaL._AC_SL1500_.jpg',
        title: 'Electronic Pet Crab Crawling Toy for Kids, Interactive Toddler Toy with Music, Lights and Obstacle Avoidance Feature, USB Rechargeable Dancing Toy for Babies Boys Girls'
      },
      {
        url: 'https://amzn.to/3OarrqA',
        img: 'https://m.media-amazon.com/images/I/81HODPTZtiL._AC_SL1500_.jpg',
        title: 'Squishmallows Pokémon 14-Inch Gengar Plush - Add Gengar to Your Squad, Ultrasoft Stuffed Animal Medium Plush, Official Kelly Toy Plush (SQPK00004)'
      },
      {
        url: 'https://amzn.to/42O4Bcu',
        img: 'https://m.media-amazon.com/images/I/71Njnx8+QoL._AC_SL1500_.jpg',
        title: 'Squishmallows Pokémon 14-Inch Pikachu Plush'
      },
      {
        url: 'https://amzn.to/3BqjFl1',
        img: 'https://m.media-amazon.com/images/I/51UEpldaHXL._AC_SL1000_.jpg',
        title: 'Zelda Wind Waker Makar Korok Plush Toy Doll 7.8inch'
      },
      {
        url: 'https://amzn.to/3OegXqo',
        img: 'https://m.media-amazon.com/images/I/71jgqdRxVsL._AC_SL1500_.jpg',
        title: 'Pokémon Snorlax Plush Stuffed Animal Toy - Large 12" - Officially Licensed - Great Gift for Kids'
      },
      {
        url: 'https://amzn.to/3I8ao4L',
        img: 'https://m.media-amazon.com/images/I/61QLYL0NYoL._AC_SL1500_.jpg',
        title: 'ensky Kirby Nosechara Stacking Figure Assortment (NOS-20), Original Version'
      }
    ]
  }
}

const getters = {
  spreadsheetSheetsList: state => state.spreadsheetSheets,
  spreadsheetItemsList: state => state.spreadsheetItems,
  itemsList: state => state.items,
  filteredItemList: state => state.filteredItems
}

const actions = {
  async fetchSpreadsheetSheets ({ commit }, vm) {
    console.log('Fetching Sheet: ' + String(state.sheetID))
    try {
      const response = await axiosInstance.get(state.sheetID + '/?alt=json&key=' + state.apiKey)
      commit('setSpreadsheetSheets', response.data.sheets)
    } catch (e) {
      console.log(e)
      // const errorDetails = helpers.drfErrorParser(e.response.data)
      // helpers.showToast('Error Fetching Item', errorDetails, 'danger', vm)
    }
  },
  async fetchSpreadsheetSheetsWithData ({ commit }, vm) {
    // console.log('Fetching Sheet: ' + String(state.sheetID))
    try {
      const response = await axiosInstance.get(state.sheetID + '/?alt=json&includeGridData=true&key=' + state.apiKey)
      const allItems = {}
      const sheetProperties = []
      response.data.sheets.forEach((sheet) => {
        sheetProperties.push({ properties: sheet.properties })
        const sheetTitle = sheet.properties.title
        allItems[sheetTitle] = []
        sheet.data[0].rowData.slice(1).forEach((item) => {
          allItems[sheetTitle].push({
            url: item.values[0].userEnteredValue.stringValue,
            title: item.values[1].userEnteredValue.stringValue,
            img: item.values[2].userEnteredValue.stringValue
          })
        })
      })
      commit('setAllSpreadsheetItems', { sheetInfo: sheetProperties, items: allItems })
    } catch (e) {
      console.log(e)
      // const errorDetails = helpers.drfErrorParser(e.response.data)
      // helpers.showToast('Error Fetching Item', errorDetails, 'danger', vm)
    }
  },
  async fetchSpreadsheetItems ({ commit }, { vm, title }) {
    console.log('Fetching Info for: ' + String(title))
    try {
      const response = await axiosInstance.get(state.sheetID + '/values/' + String(title) + '/?alt=json&key=' + state.apiKey)
      const theseItems = []
      response.data.values.slice(1).forEach((item) => {
        const thisItem = {}
        thisItem.url = item[0]
        thisItem.title = item[1]
        thisItem.img = item[2]
        theseItems.push(thisItem)
      })
      commit('setSpreadsheetItems', { title: title, items: theseItems })
    } catch (e) {
      console.log(e)
      // const errorDetails = helpers.drfErrorParser(e.response.data)
      // helpers.showToast('Error Fetching Item', errorDetails, 'danger', vm)
    }
  },
  filterItems ({ commit }, searchTerm) {
    // Returns a new filtered items object based on original items object
    const filteredData = {}
    const categories = Object.keys(state.spreadsheetItems)
    // console.log(state.spreadsheetItems)
    categories.forEach((category) => {
      const matches = state.spreadsheetItems[category].filter((item) => {
        return searchTerm.toLowerCase().split(' ').every(v => item.title.toLowerCase().includes(v))
      })
      filteredData[category] = matches
    })
    commit('setFilteredItemList', filteredData)
  }
}

const mutations = {
  setSpreadsheetSheets: (state, sheets) => (
    state.spreadsheetSheets = sheets
  ),
  setAllSpreadsheetItems (state, { sheetInfo, items }) {
    state.spreadsheetSheets = sheetInfo
    state.spreadsheetItems = items
  },
  setSpreadsheetItems: (state, { title, items }) => (
    state.spreadsheetItems[title] = items
  ),
  setItems: (state, items) => (
    state.items = items
  ),
  setFilteredItemList: (state, filteredItemList) => {
    state.filteredItems = filteredItemList
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
