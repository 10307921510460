import axios from 'axios'
import NProgress from 'vue-nprogress'

const nprogress = new NProgress()

const axiosInstance = axios.create({
  // baseURL: 'http://127.0.0.1:8000/apiv2/',
  baseURL: 'https://sheets.googleapis.com/v4/spreadsheets/',
  timeout: 60000,
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json'
  }
})

// axiosInstance.defaults.withCredentials = true
// axiosInstance.defaults.xsrfCookieName = 'csrftoken'
// axiosInstance.defaults.xsrfHeaderName = 'X-CSRFToken'

axiosInstance.interceptors.request.use((config) => {
  // Do something before request is sent
  nprogress.start()
  return config
}, (e) => {
  // Do something with request error
  console.log(e)
  return Promise.reject(e)
})

// Interceptor to handle errors from API calls
axiosInstance.interceptors.response.use((response) => {
  nprogress.done()
  return response
}, (e) => {
  nprogress.done()
  if (!e.response) {
    e.response = {}
    e.response.status = 499
    e.response.data = {}
    e.response.data.detail = String(e.code) + ': ' + String(e.message)
  }
  if (e.response) {
    if (e.response.status === 403) {
      // User isn't logged in or doesn't have credentials set
      if (e.response.data.detail === 'Authentication credentials were not provided.') {
        location.reload()
      }
    }
  }
  return Promise.reject(e)
})

export default axiosInstance
