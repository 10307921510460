<template>
  <b-container fluid="xl">
    <div class="row">
      <b-col class="p-5">
        <b-link
          href="https://www.instagram.com/ahundreditems/"
          target="_blank"
        >
          <font-awesome-icon
            :icon="[ 'fab', 'instagram' ]"
            class="p-2 social-icon"
          />
        </b-link>
        <b-link
          href="https://www.tiktok.com/@ahundreditems"
          target="_blank"
        >
          <font-awesome-icon
            :icon="[ 'fab', 'tiktok' ]"
            class="p-2 social-icon"
          />
        </b-link>
        <b-link
          href="https://twitter.com/ahundredItems"
          target="_blank"
        >
          <font-awesome-icon
            :icon="[ 'fab', 'twitter' ]"
            class="p-2 social-icon"
          />
        </b-link>
      </b-col>
    </div>
    <div class="row">
      <b-col class="pt-5">
        We participate in the Amazon Associates Program and earn from qualifying purchases.
      </b-col>
    </div>
    <div class="row">
      <b-col class="pb-5">
        <PrivacyPolicyModal />
        <TermsOfServiceModal />
      </b-col>
    </div>
  </b-container>
</template>

<script>
import PrivacyPolicyModal from './PrivacyPolicyModal'
import TermsOfServiceModal from './TermsOfServiceModal'

export default {
  name: 'Footer',
  data () {
    return {
    }
  },
  components: {
    PrivacyPolicyModal,
    TermsOfServiceModal
  },
  props: {
    msg: String
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.social-icon {
  font-size: 2em;
}
</style>
