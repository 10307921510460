<template>
  <div>
    <b-button variant="link" id="privacy-modal-toggle-btn" @click="toggleModal">
      Privacy Policy
    </b-button>

    <b-modal ref="privacy-modal" hide-footer header-class="title" title="Privacy Policy">
      <p>At AHundredItems.com, we take your privacy seriously. This Privacy Policy outlines how we handle your personal information when you interact with our website.</p>

      <h5 class="title">Information Collection:</h5>
      <p class="ml-2">We want to assure you that we do not directly collect any personal data from our customers. When you visit our website, we do not collect any personally identifiable information such as your name, address, email, or phone number.</p>

      <h5 class="title">Use of Cookies:</h5>
      <p class="ml-2">To enhance your browsing experience, we may use cookies on our website. Cookies are small text files that are stored on your device when you visit certain websites. These cookies help us analyze website traffic, track user interactions, and customize content based on your preferences. However, these cookies do not collect any personal information.</p>

      <h5 class="title">Third-Party Links:</h5>
      <p class="ml-2">Our website may contain links to third-party websites, including affiliate links. Please note that once you leave our website and visit these external sites, our privacy policy no longer applies. We encourage you to review the privacy policies of any third-party websites you visit.</p>

      <h5 class="title">Data Security:</h5>
      <p class="ml-2">We prioritize the security of your personal information. While we do not collect any personal data directly, we take reasonable measures to protect our website and ensure the security of any information you voluntarily provide through external links or forms.</p>

      <h5 class="title">Children's Privacy:</h5>
      <p class="ml-2">Our website is not intended for individuals under the age of 13. We do not knowingly collect or store any personal information from children.</p>

      <h5 class="title">Changes to the Privacy Policy:</h5>
      <p class="ml-2">We reserve the right to update or modify this Privacy Policy at any time. Any changes will be reflected on this page, and we encourage you to review it periodically.</p>

      <p>By using our website, you acknowledge that you have read and understood this Privacy Policy and agree to its terms and conditions.</p>

      <p>This Privacy Policy was last updated on 05/2023.</p>
      <b-button class="mt-3 title" variant="outline-danger" block @click="hideModal">Close</b-button>
    </b-modal>
  </div>
</template>

<script>
export default {
  name: 'PrivacyPolicyModal',
  data () {
    return {
      modalShow: false
    }
  },
  methods: {
    showModal () {
      this.$refs['privacy-modal'].show()
    },
    hideModal () {
      this.$refs['privacy-modal'].hide()
    },
    toggleModal () {
      // We pass the ID of the button that we want to return focus to
      // when the modal has hidden
      this.$refs['privacy-modal'].toggle('#privacy-modal-toggle-btn')
    },
    checkURLAnchor () {
      const url = window.location.href
      const hash = url.split('#').pop()
      if (hash === 'privacy') {
        this.showModal()
      }
    }
  },
  mounted () {
    this.checkURLAnchor()
  }
}
</script>

<style scoped>
.title {
  font-family: 'Bebas Neue', sans-serif;
}
</style>
