<template>
  <div>
    <span id="toggle-btn" @click="toggleModal" class="material-symbols-outlined">
    info
    </span>

    <b-modal ref="modal-info" hide-footer title="💯">
      <h5 class="title">Welcome to A Hundred Items</h5>
      <p>The ultimate destination for an unparalleled selection of products listed on Amazon. With our meticulously curated collection of rotating items, we bring you the very best that Amazon has to offer. From the latest tech gadgets to trendy fashion accessories, we've got it all covered.</p>

      <h5 class="title">Unleash the Power of Variety</h5>
      <p>At AHundredItems.com, we understand that everyone has unique tastes and preferences. That's why we've handpicked a rotating selection of a hundred items, ensuring that there's something for everyone. Discover hidden gems, limited-time offers, and exclusive deals that might have slipped under your radar.</p>

      <h5 class="title">Unparalleled Convenience</h5>
      <p>Finding what you need has never been easier. Our user-friendly interface allows you to navigate through our extensive catalog effortlessly. Search for specific items or explore new categories with our powerful search functionality.</p>

      <h5 class="title">Stay One Step Ahead</h5>
      <p>We constantly monitor Amazon's vast inventory and update our collection accordingly, ensuring you have access to the latest trends. From the hottest tech releases to the most popular fashion statements, we keep you on top of the game.</p>

      <h5 class="title">Unleash the Potential of Your Passion</h5>
      <p>Whether you're a tech enthusiast, a fashion-forward individual, or a savvy shopper looking for the best deals, AHundredItems.com is your go-to resource. Explore our diverse range of products and find the tools, inspiration, and recommendations you need to make informed purchasing decisions.</p>

      <h5 class="title">Discover, Explore, and Shop</h5>
      <p>Ready to embark on a journey of endless possibilities? We're here to make your shopping experience seamless, enjoyable, and rewarding. Start exploring now and unlock the full potential of your passions!</p>

      <b-button class="mt-3 title" variant="outline-danger" block @click="hideModal">Close</b-button>
    </b-modal>
  </div>
</template>

<script>
export default {
  name: 'ModalInfo',
  data () {
    return {
      modalShow: false
    }
  },
  methods: {
    showModal () {
      this.$refs['modal-info'].show()
    },
    hideModal () {
      this.$refs['modal-info'].hide()
    },
    toggleModal () {
      // We pass the ID of the button that we want to return focus to
      // when the modal has hidden
      this.$refs['modal-info'].toggle('#toggle-btn')
    }
  }
}
</script>

<style>
.title {
  font-family: 'Bebas Neue', sans-serif;
}
</style>
