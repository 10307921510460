<template>
  <div>
    <b-button variant="link" id="terms-modal-toggle-btn" @click="toggleModal">
      Terms of Service
    </b-button>

    <b-modal ref="terms-modal" hide-footer header-class="title" title="Terms of Service">
      <p class="terms-content">
        These Terms of Service ("Terms") govern your use of the AHundredItems.com website ("Website"). By accessing or using the Website, you agree to be bound by these Terms.
      </p>

      <h5 class="title">Website Usage:</h5>
      <p class="terms-content">
        a. You must be at least 13 years old to use the Website. If you are under 13, please refrain from accessing or using the Website.
        b. You agree to use the Website in compliance with all applicable laws and regulations.
        c. You are responsible for maintaining the confidentiality of any login credentials or account information associated with the Website.
      </p>

      <h5 class="title">Intellectual Property:</h5>
      <p class="terms-content">
        a. The content on the Website, including text, images, logos, trademarks, and other materials, is protected by intellectual property rights and is owned or licensed by AHundredItems.com.
        b. You may not modify, reproduce, distribute, or sell any of the content from the Website without prior written permission from AHundredItems.com.
      </p>

      <h5 class="title">Third-Party Links:</h5>
      <p class="terms-content">
        a. The Website may contain links to third-party websites or services that are not owned or controlled by AHundredItems.com. We are not responsible for the content, privacy practices, or availability of such third-party sites.
        b. Clicking on any third-party links is done at your own risk, and you should review the terms and privacy policies of those third-party websites.
      </p>

      <h5 class="title">Limitation of Liability:</h5>
      <p class="terms-content">
        a. The Website and its content are provided on an "as-is" and "as available" basis, without warranties of any kind, whether express or implied.
        b. AHundredItems.com does not guarantee the accuracy, completeness, or reliability of the content on the Website.
        c. In no event shall AHundredItems.com be liable for any direct, indirect, incidental, consequential, or special damages arising out of or in connection with your use of the Website.
      </p>

      <h5 class="title">Indemnification:</h5>
      <p class="terms-content">
        You agree to indemnify and hold AHundredItems.com, its affiliates, officers, directors, and employees harmless from any claims, losses, damages, liabilities, or expenses arising out of your use of the Website or any violation of these Terms.
      </p>

      <h5 class="title">Modifications:</h5>
      <p class="terms-content">
        AHundredItems.com reserves the right to modify, suspend, or discontinue the Website or any part thereof at any time without prior notice. We may also revise these Terms from time to time. The updated Terms will be effective upon posting on the Website.
      </p>

      <h5 class="title">Governing Law:</h5>
      <p class="terms-content">
        These Terms shall be governed by and construed in accordance with the laws of California, without regard to its conflict of laws principles.
      </p>

      <p class="terms-content">
        By using the Website, you acknowledge that you have read, understood, and agreed to these Terms.
      </p>
      <b-button class="mt-3 title" variant="outline-danger" block @click="hideModal">Close</b-button>
    </b-modal>
  </div>
</template>

<script>
export default {
  name: 'TermsOfServiceModal',
  data () {
    return {
      modalShow: false
    }
  },
  methods: {
    showModal () {
      this.$refs['terms-modal'].show()
    },
    hideModal () {
      this.$refs['terms-modal'].hide()
    },
    toggleModal () {
      // We pass the ID of the button that we want to return focus to
      // when the modal has hidden
      this.$refs['terms-modal'].toggle('#terms-modal-toggle-btn')
    },
    checkURLAnchor () {
      const url = window.location.href
      const hash = url.split('#').pop()
      if (hash === 'tos') {
        this.showModal()
      }
    }
  },
  mounted () {
    this.checkURLAnchor()
  }
}
</script>

<style scoped>
.title {
  font-family: 'Bebas Neue', sans-serif;
}
.terms-content {
  white-space: pre-line;
}
</style>
