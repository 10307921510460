<template>
  <div id="app">
    <b-jumbotron header="💯" lead="">
      <p>
        A hundred items <br />
        Curated and categorized
      </p>
      <InfoModal />
    </b-jumbotron>
    <Items />
    <Footer />
  </div>
</template>

<script>
import Items from './components/Items'
import Footer from './components/Footer'
import InfoModal from './components/InfoModal'

export default {
  name: 'App',
  components: {
    Items,
    Footer,
    InfoModal
  },
  data () {
    return {
    }
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

#nav {
  padding: 30px;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
}
</style>
