<template>
  <b-container v-if="items.length" fluid="xl">
    <div class="row">
        <b-col>
          <h4
            :class="visible ? null : 'collapsed'"
            :aria-expanded="visible ? 'true' : 'false'"
            :aria-controls="'collapse-' + category"
            @click="visible = !visible"
          >
            <span class="when-opened material-symbols-outlined va-center float-left">
              expand_more
            </span>
            <span class="when-closed material-symbols-outlined va-center float-left">
              chevron_right
            </span>
            <span class="category-title float-left">{{ category }}</span>
          </h4>
        </b-col>
    </div>
    <b-collapse v-bind:id="'collapse-' + category" v-model="visible" class="mt-2">
      <b-row cols="2" cols-sm="3" cols-md="4" cols-lg="5">
        <b-col
          v-for="item in items"
          v-bind:key="item.index"
          class="item-listing p-5"
        >
          <b-link
            v-bind:href="item.url"
            target="_blank"
          >
            <b-img
              class="height-adjusted"
              v-bind:src="item.img"
              v-bind:alt="item.title"
              v-bind:title="item.title"
              fluid
            ></b-img>
            <!-- {{ item.title }} -->
          </b-link>
        </b-col>
      </b-row>
    </b-collapse>
  </b-container>
</template>

<script>
import azLogo from '../../assets/az_logo.png'

export default {
  name: 'CategoryItems',
  data () {
    return {
      visible: true,
      filterQuery: null
    }
  },
  props: {
    category: String,
    items: Array,
    query: String
  },
  computed: {
  },
  created: function () {
    if (this.items) {
      for (let count = this.items.length + 1; count < 11; count++) {
        if (this.items.length < 11) {
          this.items.push(
            {
              url: 'https://amzn.to/41GLC2y',
              img: azLogo,
              title: 'Amazon ' + String(count)
            }
          )
        }
      }
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
.item-listing {
  align-self: center;
}
.filter-input {
  text-align: center;
}
.collapsed > .when-opened, :not(.collapsed) > .when-closed {
  display: none;
}
.height-adjusted {
  max-height: 150px;
}
</style>
